<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M9.25863 10.2555L9.87932 9.63479C10.1276 9.3865 10.1276 8.95199 9.81726 8.64162L8.63794 7.71052C8.38967 7.5243 8.07932 7.5243 7.83105 7.7726L6.96208 8.64162L3.85862 5.53796L4.66552 4.66893C4.9138 4.48271 4.9138 4.11027 4.72759 3.86198L3.79656 2.68259C3.54828 2.37222 3.1138 2.37222 2.86552 2.62051L2.24483 3.24125C1.25172 4.23442 1.25172 5.78625 2.24483 6.77942L5.78277 10.2555C6.7138 11.2487 8.26553 11.2487 9.25863 10.2555Z"
    fill="currentColor"
  />
  <path
    d="M7.43112 2.15976C7.2181 1.94675 6.87279 1.94675 6.65977 2.15976C6.44674 2.3728 6.44674 2.71811 6.65977 2.93113L7.72858 4.00001L6.65977 5.06889C6.44674 5.28191 6.44674 5.62722 6.65977 5.84024C6.87279 6.05325 7.2181 6.05325 7.43112 5.84024L8.5 4.77143L9.56888 5.84024C9.7819 6.05325 10.1272 6.05325 10.3402 5.84024C10.5533 5.62722 10.5533 5.28191 10.3402 5.06889L9.27142 4.00001L10.3402 2.93113C10.5533 2.71811 10.5533 2.3728 10.3402 2.15976C10.1272 1.94675 9.7819 1.94675 9.56888 2.15976L8.5 3.22859L7.43112 2.15976Z"
    fill="currentColor"
  />
</template>
